import { AccessConfigType, RoleEnum } from "@cm/ui-modules";

export const AccessConfig: AccessConfigType = {
  'dashboard': Object.values(RoleEnum).filter(role => role !== RoleEnum.DOC && role !== RoleEnum.REP),
  'campaigns': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'medialibrary': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'messages': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'meetings': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'consent': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.AUD, RoleEnum.DTM],
  'privilege': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.AUD, RoleEnum.DTM],
  'configuration': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.AUD, RoleEnum.DTM],

  'contacts': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'workplaces': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'groups': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'specializations': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'custom-fields': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'leads': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],

  'contacts.import': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'batch-job': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],

  'automations': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.DTM],
  'presentations': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX],
  'forms': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'challenges': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'courses': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SGFX, RoleEnum.GFX, RoleEnum.AUD, RoleEnum.DTM],
  'administrations': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.AUD, RoleEnum.DTM],
  'billing': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.SRF, RoleEnum.AUD, RoleEnum.ADM,  RoleEnum.FIN],
  'sfdc': [],
  'education': [],
  'datamanager': [RoleEnum.DTM],
  'segments': [RoleEnum.PM, RoleEnum.SRV, RoleEnum.AUD, RoleEnum.DTM],
  'editbilling': [RoleEnum.ADM, RoleEnum.SRF],
  'addusage': [RoleEnum.ADM, RoleEnum.SRV, RoleEnum.SRF],
  'knowhow': [RoleEnum.PM, RoleEnum.ADM, RoleEnum.SRV, RoleEnum.SRF],
};
